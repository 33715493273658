export const mixinVideo = {
  data() {
    return {
      // 对齐方式
      position_list: [{
          img: require('@/assets/images/creation/creationMng_(65).png'),
          imgActive: require('@/assets/images/creation/creationMng_(73).png'),
          value: 'left'
        },
        {
          img: require('@/assets/images/creation/creationMng_(8).png'),
          imgActive: require('@/assets/images/creation/creationMng_(75).png'),
          value: 'center'
        },
        {
          img: require('@/assets/images/creation/creationMng_(7).png'),
          imgActive: require('@/assets/images/creation/creationMng_(74).png'),
          value: 'right'
        },
      ],
      // 字体库    
      fontfamilly: [{
          label: 'Helvetica',
          value: 'Helvetica',
        },
        {
          label: 'Arial',
          value: 'Arial',
        },
        {
          label: 'Tahoma',
          value: 'Tahoma',
        },
        {
          label: 'San Francisco',
          value: 'San Francisco',
        },
        {
          label: '苹方-简',
          value: 'PingFang SC',
        },
        {
          label: '冬青黑体',
          value: 'Hiragino Sans GB',
        },
        {
          label: '黑体',
          value: 'Heiti SC',
        },
        {
          label: '微软雅黑',
          value: 'Microsoft YaHei',
        },
        {
          label: '文泉驿微米黑',
          value: 'WenQuanYi Micro Hei',
        },
        {
          label: '思源黑体',
          value: 'Noto Sans',
        },
        {
          label: '楷体',
          value: '楷体',
        },
        {
          label: '宋体',
          value: 'SimSun',
        },
        {
          label: '仿宋',
          value: '仿宋',
        },
        {
          label: '新宋体',
          value: '新宋体',
        },
        {
          label: '幼圆',
          value: '幼圆',
        },
        {
          label: '隶书',
          value: '隶书',
        },

      ],
      // 字体模板
      fontTemplate: [{
          img: require('@/assets/images/creation/creationMng_(71).png'),
          value: 0,
          color: '',
          strokeColor: '',
          strokeWidth: 0, // 描边
        },
        // 白底黑边
        {
          img: require('@/assets/images/creation/creationMng_(61).png'),
          value: 1,
          color: 'rgb(255, 255, 255)',
          strokeColor: 'rgb(1, 1, 1)',
          strokeWidth: 0, // 描边
        },
        // 黑底白边
        {
          img: require('@/assets/images/creation/creationMng_(60).png'),
          value: 2,
          color: 'rgb(1, 1, 1)',
          strokeColor: 'rgb(255, 255, 255)',
          strokeWidth: 0, // 描边
        },
        // 黄底黑边
        {
          img: require('@/assets/images/creation/creationMng_(59).png'),
          value: 3,
          color: 'rgb(255, 220, 56)',
          strokeColor: 'rgb(1, 1, 1)',
          strokeWidth: 0, // 描边
        },
        // 白底粉边
        {
          img: require('@/assets/images/creation/creationMng_(58).png'),
          value: 4,
          color: 'rgb(255, 255, 255)',
          strokeColor: 'rgb(232, 146, 134)',
          strokeWidth: 0, // 描边
        },
        // 蓝底黑边
        {
          img: require('@/assets/images/creation/creationMng_(57).png'),
          value: 5,
          color: 'rgb(182, 219, 247)',
          strokeColor: 'rgb(1, 1, 1)',
          strokeWidth: 0, // 描边
        },
        // 粉底粉边
        {
          img: require('@/assets/images/creation/creationMng_(56).png'),
          value: 6,
          color: 'rgb(253, 217, 229)',
          strokeColor: 'rgb(250, 107, 157)',
          strokeWidth: 0, // 描边
        },
        // 蓝底蓝边
        {
          img: require('@/assets/images/creation/creationMng_(55).png'),
          value: 7,
          color: 'rgb(195, 238, 255)',
          strokeColor: 'rgb(90, 154, 230)',
          strokeWidth: 0, // 描边
        },
        // 绿底绿边
        {
          img: require('@/assets/images/creation/creationMng_(54).png'),
          value: 8,
          color: 'rgb(199, 204, 86)',
          strokeColor: 'rgb(69, 92, 44)',
          strokeWidth: 0, // 描边
        },
        // 靛底靛边
        {
          img: require('@/assets/images/creation/creationMng_(53).png'),
          value: 9,
          color: 'rgb(150, 194, 204)',
          strokeColor: 'rgb(64, 87, 107)',
          strokeWidth: 0, // 描边
        },
        // 白底红边
        {
          img: require('@/assets/images/creation/creationMng_(52).png'),
          value: 10,
          color: 'rgb(255, 255, 255)',
          strokeColor: 'rgb(251, 53, 70)',
          strokeWidth: 0, // 描边
        },
        // 红底白边
        {
          img: require('@/assets/images/creation/creationMng_(51).png'),
          value: 11,
          color: 'rgb(173, 73, 57)',
          strokeColor: 'rgb(255, 255, 255)',
          strokeWidth: 0, // 描边
        },
        {
          img: require('@/assets/images/creation/creationMng_(50).png'),
          value: 12,
          color: 'rgb(247, 243, 198)',
          strokeColor: 'rgb(68, 67, 49)',
          strokeWidth: 0, // 描边
        },
        // 棕底棕边
        {
          img: require('@/assets/images/creation/creationMng_(62).png'),
          value: 13,
          color: 'rgb(246, 214, 197)',
          strokeColor: 'rgb(155, 84, 87)',
          strokeWidth: 0, // 描边
        },
      ],
      singleBg: [ // 纯色背景
        '#EB5757',
        '#F1994A',
        '#F1CA4B',
        '#6FCF97',
        '#2F80ED',
        '#FFFFFF',
      ],
      // 动效
      animates: {
        prompt: [{
            name: '无动效',
            type: 'none',
            img: '',
            gif: ''
          },
          {
            name: '上下摆动',
            type: 'shakeY',
            img: '',
            gif: ''
          },
          {
            name: '左右摆动',
            type: 'shakeX',
            img: '',
            gif: ''
          },
          {
            name: '弹跳',
            type: 'bounce',
            img: '',
            gif: ''
          },
          {
            name: '心跳',
            type: 'heartBeat',
            img: '',
            gif: ''
          },
          {
            name: '摆动',
            type: 'wobble',
            img: '',
            gif: ''
          },
          {
            name: '摇头',
            type: 'headShake',
            img: '',
            gif: ''
          },
          {
            name: '秋千',
            type: 'swing',
            img: '',
            gif: ''
          },
          {
            name: '脉冲',
            type: 'pulse',
            img: '',
            gif: ''
          },
        ],
        in_effect: [{
            name: '无动效',
            type: 'none',
            img: '',
            gif: ''
          },
          {
            name: '从上进入',
            type: 'backInDown',
            img: '',
            gif: ''
          },
          {
            name: '从下进入',
            type: 'backInUp',
            img: '',
            gif: ''
          },
          {
            name: '从右进入',
            type: 'backInRight',
            img: '',
            gif: ''
          },
          {
            name: '从左进入',
            type: 'backInLeft',
            img: '',
            gif: ''
          },
          {
            name: '旋转进入',
            type: 'rotateIn',
            img: '',
            gif: ''
          },
          {
            name: '变大进入',
            type: 'zoomIn',
            img: '',
            gif: ''
          },

        ],
        out_effect: [{
            name: '无动效',
            type: 'none',
            img: '',
            gif: ''
          },
          {
            name: '从上退出',
            type: 'backOutUp',
            img: '',
            gif: ''
          },
          {
            name: '从下退出',
            type: 'backOutDown',
            img: '',
            gif: ''
          },
          {
            name: '从右退出',
            type: 'backOutRight',
            img: '',
            gif: ''
          },
          {
            name: '从左退出',
            type: 'backOutLeft',
            img: '',
            gif: ''
          },
          {
            name: '旋转退出',
            type: 'rotateOut',
            img: '',
            gif: ''
          },
          {
            name: '缩小退出',
            type: 'zoomOut',
            img: '',
            gif: ''
          },

        ]
      },
    }
  },
  computed: {

  },
  created() {},
  mounted() {},
  methods: {},
}
