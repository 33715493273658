<template>
    <div class="setcon1">
        <div class="tabs">
            <div class="item" :class="{ 'active': tabValue == item.value }" v-for="(item, index) in tabs" :key="index" @click="tabChange(item)">
                <div class="label">{{ item.label }}</div>
                <div class="bar"></div>
            </div>
        </div>

        <div class="tab-1-con scroll_bar" v-if="tabValue == 1">
            <div class="title">场景属性</div>
            <div class="sec-item">
                <div class="sec-title">背景图片</div>
                <div class="bg-box" :style="[bgStyle]">
                    <!-- <el-image v-if="background.type == 'image'" style="width: 304px; height: 170px" :src="background.url" fit="contain"></el-image> -->
                </div>
                <div class="btn-box">
                    <div class="btn">
                        <!-- 本地上传 -->
                        <el-upload ref="uploadRef" action="#" :show-file-list="false" accept="image/*, video/mp4, video/webm" :http-request="uploadChange">
                            <div slot style="width: 151px; height: 100%;" class="btn">
                                本地上传
                            </div>
                        </el-upload>
                    </div>
                    <div class="btn1-line"></div>
                    <div class="btn" @click="resetChoose()">重新选择</div>
                </div>
            </div>
            <div class="sec-item">
                <div class="sec-title">纯色背景</div>
                <div class="list">
                    <div class="item" @click="colorChange('transparent')">
                        <img :src="require('@/assets/images/workben/color.png')" alt="">
                    </div>
                    <div class="item" :style="{ background: item }" v-for="(item, index) in singleBg" :key="index" @click="colorChange(item)"></div>
                    <div class="item">
                        <el-color-picker v-model="color" @change="colorChange"></el-color-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-2-con scroll_bar" v-if="tabValue == 2">
            <draggable v-model="layerlist" :animation="500" @end="draggableEnd">
                <transition-group>
                    <div class="item" v-for="(item, index) in layerlist" :key="item.id">
                        <div class="item-l">
                            <img class="icon" v-if="item.type == 'text'" :src="require('@/assets/images/workben/layer1.png')" alt="">
                            <img class="icon" v-else-if="item.type == 'video'" :src="require('@/assets/images/workben/layer4.png')" alt="">
                            <img class="icon" v-else-if="item.type == 'subtitle'" :src="require('@/assets/images/workben/layer3.png')" alt="">
                            <div class="icon-box" v-else>
                                <el-image style="width: 24px; height: 24px;" :src="item.url" fit="cover"></el-image>
                            </div>
                            <div class="name" v-if="item.type == 'text'">{{ item.content }}</div>
                            <div class="name" v-else>{{ item.name }}</div>
                        </div>
                        <div class="item-r">
                            <div class="lock" @click="lockBtn(item)">
                                <img v-if="item.lock" :src="require('@/assets/images/workben/layer6.png')" alt="">
                                <img v-else :src="require('@/assets/images/workben/layer7.png')" alt="">
                            </div>
                            <div class="delete" @click="delBtn(index)">
                                <img :src="require('@/assets/images/workben/layer5.png')" alt="">
                            </div>
                        </div>
                    </div>
                </transition-group>
            </draggable>
        </div>
        <!-- 删除的提示 -->
        <point-out ref="point" @close='confirmBtn' dialogTitle="提示" dialogWidth='380px' type="works" iconColor="#FF9665" borderRadius='10px' :iconShow="true" :affirmShow="true" affirmText='确 定'>
            <div class="zc_dialog_tip_content">
                <div class="text">确认删除该图层吗？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import draggable from 'vuedraggable' // 拖动
import { mixinVideo } from "@/mixin/video"
import { mapState } from "vuex"
import PointOut from '@/components/point_out/point_out'

export default {
    mixins: [mixinVideo],
    components: {
        draggable,
        PointOut,
    },
    data() {
        return {
            tabs: [
                {
                    label: '页面属性',
                    value: 1
                },
                {
                    label: '图层管理',
                    value: 2
                }
            ],
            tabValue: 1,
            color: null,
            colorValue: '',
            layerlist: [],//图层
            layerIndex: 0,
        }
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        background() {
            return this.sceneList[this.sceneIndex].background || {}
        },
        list() {
            if (this.sceneList.length) {
                let arr = this.sceneList[this.sceneIndex].scenes_conf || []
                return arr
            } else {
                return []
            }
        },
        bgStyle() {
            let params = {}
            if (this.background.type == 'color') {
                params.background = this.background.color
            }
            if (this.background.type == 'image') {
                params.background = 'url(' + this.background.url + ') no-repeat 100%/cover'
            }
            return params
        },
    },
    watch: {
        list() {
            this.getList()
        },
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let newArr = []
            this.list.forEach(ele => {
                if (ele.type != 'audio') {
                    newArr.push(ele)
                }
            })
            this.layerlist = newArr
        },
        // 拖动结束
        draggableEnd() {
            this.changeList()
        },
        // 修改数据
        changeList() {
            let audioList = []
            this.list.forEach(ele => {
                if (ele.type == 'audio') {
                    audioList.push(ele)
                }
            })
            let arr = this.layerlist.concat(audioList);
            var params = {
                type: 'conf',
                info: arr
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        // tab切换
        tabChange(item) {
            this.tabValue = item.value
        },
        // 颜色
        colorChange(val) {
            this.colorValue = val
            this.background.type = 'color'
            this.background.url = ''
            this.background.color = val
        },
        // 删除
        delBtn(index) {
            this.layerIndex = index
            this.$refs.point.openDialogBtn()
        },
        // 确认删除的回调
        confirmBtn() {
            this.layerlist.splice(this.layerIndex, 1)
            this.$store.commit('videoData/SET_SHOW_ID', null)
            this.changeList()
            return
        },
        // 上传之前的钩子函数
        uploadChange(response) {
            const file = response.file
            console.log(file)
            const checkSize = file.size / 1024 / 1024 <= 50
            // 判断大小
            if (!checkSize) {
                this.$message({
                    message: `只能上传不超过50M大小的文件`,
                    type: 'warning'
                })
                return false
            }
            // 上传的进度
            const config = {
                onUploadProgress: progressEvent => {
                    this.progressPercent = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
                    // console.log(this.progressPercent)
                }
            }
            const newFile = new FormData()
            newFile.append('file', file)
            newFile.append('type', 2)
            this.$workbenApi.up_material(newFile, config).then(res => {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: res.code === 1000 ? 'success' : 'error'
                })
                if (res.code === 1000) {
                    this.background.type = 'image'
                    this.background.url = res.result.thumbnail
                }
            })
        },
        // 重新选择
        resetChoose() {
            let obj = {
                label: '背景',
                value: 'background',
                navFlag: true,
                icon: require('@/assets/images/icon/video_nav_2.png'),
            }
            this.$store.commit('videoCreate/SET_RIGHT_NAV_INFO', obj)
        },

        // 锁住层级
        lockBtn(item) {
            this.$set(item, 'lock', !item.lock)
        }
    }
}
</script>

<style lang="scss" scoped>
.setcon1 {
    padding: 20px;
    // height: 100%;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #454a50;

    .item {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .label {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
        }

        .bar {
            width: 28px;
            height: 2px;
            background: transparent;
            border-radius: 1px;
            margin-top: 5px;
        }
    }

    .active {
        .label {
            font-weight: 500;
        }

        .bar {
            background: #ffffff;
        }
    }
}

.tab-1-con {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -8px;

    .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 20px;
    }

    .sec-item {
        margin-top: 20px;

        .sec-title {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c1c1c1;
        }

        .bg-box {
            margin-top: 12px;
            width: 304px;
            height: 170px;
            background: #1b1d1f;
            border-radius: 2px 2px 0px 0px;
        }

        .btn-box {
            width: 304px;
            height: 36px;
            background: #3a4858;
            border-radius: 0px 0px 2px 2px;
            display: flex;
            align-items: center;

            .btn {
                width: 50%;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
            }

            .btn1-line {
                width: 1px;
                height: 21px;
                background: #67727f;
            }
        }

        .list {
            display: flex;
            align-items: center;
            margin-top: 12px;

            .item {
                width: 38px;
                height: 26px;
                cursor: pointer;

                // 控制其宽高
                /deep/ .el-color-picker .el-color-picker__trigger {
                    width: 38px;
                    height: 26px;
                    border-radius: 0;
                    padding: 0;
                    border: none;
                }
            }
        }
    }
}

// .contentbox {
//   overflow: auto;
//   height: calc(100% - 100px);
//   margin-right: -8px;
// }
.tab-2-con {
    background: #272c32;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 100px);
    margin-right: -8px;

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 284px;
        height: 46px;
        cursor: pointer;

        .item-l {
            display: flex;
            align-items: center;

            .icon {
                width: 26px;
                height: 26px;
                background: #3d444c;
                border-radius: 4px;
            }

            .icon-box {
                width: 26px;
                height: 26px;
                background: #3d444c;
                border-radius: 4px;
            }

            .name {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-left: 12px;
            }
        }

        .item-r {
            display: flex;

            .lock {
                width: 12px;
                height: 12px;
            }

            .delete {
                width: 12px;
                height: 12px;
                margin-left: 20px;
            }
        }

        &:hover {
            background: #1b1d1f;
            border-radius: 2px;
        }
    }

    .active {
        background: #1b1d1f;
        border-radius: 2px;
    }
}
</style>